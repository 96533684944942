import React from "react";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import Theme from "../../theme";

function FinishedCard() {
  let showAddress = false;

  return (
    <div
      style={{
        backgroundColor: "#fff",
        border: "1px solid #E0E0E0",
        borderRadius: 10,
        width: "90%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        paddingBottom: 20,
        marginTop: "2%",
      }}
    >
      <div
        style={{
          width: "100%",
          height: 12,
          borderRadius: "8px 8px 0 0",
          position: "relative",
          top: 0,
          backgroundColor: "#D4D4D4",
        }}
      />

      <div style={{ display: "flex", width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <p
            style={{
              fontFamily: "Poppins",
              fontSize: 16,
              marginBottom: 20,
              marginLeft: 60,
            }}
          >
            Serviços:
          </p>
          <div>
            <div style={{ display: "flex", marginBottom: 7 }}>
              <div
                style={{
                  width: 50,
                  height: 50,
                  marginRight: 5,
                  backgroundColor: "red",
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  height: 50,
                  marginRight: 20,
                }}
              >
                <p
                  style={{
                    fontWeight: 600,
                    fontFamily: "Poppins",
                    fontSize: 16,
                  }}
                >
                  1X Banho e Tosa
                </p>
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: 12,
                  }}
                >
                  Sem mais informações
                </p>
              </div>
              <div
                style={{
                  width: 50,
                  height: 50,
                  marginRight: 5,
                  backgroundColor: "red",
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  height: 50,
                }}
              >
                <p
                  style={{
                    fontWeight: 600,
                    fontFamily: "Poppins",
                    fontSize: 16,
                  }}
                >
                  Serviço de retirada
                </p>
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: 12,
                  }}
                >
                  Retirada e entrega do pet
                </p>
              </div>
            </div>
            <div style={{ display: "flex", marginBottom: 2 }}>
              <p
                style={{
                  fontFamily: "Poppins",
                  fontSize: 14,
                  marginRight: 5,
                }}
              >
                Horário:
              </p>
              <p
                style={{
                  fontWeight: 700,
                  fontFamily: "Poppins",
                  fontSize: 14,
                }}
              >
                16:30
              </p>
            </div>
            <div style={{ display: "flex", marginBottom: 2 }}>
              <p
                style={{
                  fontFamily: "Poppins",
                  fontSize: 14,
                  marginRight: 5,
                }}
              >
                Pet:
              </p>
              <p
                style={{
                  fontWeight: 700,
                  fontFamily: "Poppins",
                  fontSize: 14,
                }}
              >
                Sem raça definida, porte M
              </p>
            </div>
            <div style={{ display: "flex", marginBottom: 2 }}>
              <p
                style={{
                  fontFamily: "Poppins",
                  fontSize: 14,
                  marginRight: 5,
                }}
              >
                Endereço:
              </p>
              <p
                style={{
                  fontWeight: 700,
                  fontFamily: "Poppins",
                  fontSize: 14,
                }}
              >
                Rua Campevas 363, apto 5116:30
              </p>
            </div>
            <div style={{ display: "flex", margin: "46px 0" }}>
              <p
                style={{
                  fontFamily: "Poppins",
                  fontSize: 36,
                  marginRight: 5,
                }}
              >
                Valor:
              </p>
              <p
                style={{
                  fontWeight: 700,
                  fontFamily: "Poppins",
                  fontSize: 36,
                }}
              >
                R$ 78,00
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            margin: "0 30px 30px 0",
          }}
        >
          <div
            style={{
              marginTop: 50,
              display: "flex",
              flex: 2,
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                fontWeight: 700,
                fontFamily: "Poppins",
                fontSize: 45,
              }}
            >
              Concluído
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flex: 5,
              flexDirection: "column",
            }}
          >
            <CheckCircleOutlineRoundedIcon
              style={{
                position: "relative",
                top: 50,
                left: 140,
                color: "#004FC6",
                width: 100,
                height: 100,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FinishedCard;
