import { privateHttp } from "../../service/http";
import theme from "../../theme";
import Loading from "../../components/loading";
import React, { useState, useEffect } from "react";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export default (options) => {
  // fazer paginacao com url e show.item

  const show = {
    item: options.items ? options.items : 50,
    pagination: options.pagination ? options.pagination : false,
    autoLoad: options.pagination?.autoLoad ? options.pagination.autoLoad : 75,
  };

  const [eleLoad, setEleLoad] = useState(true);

  const [data, setData] = useState(
    options.data
      ? {
          style: options.data.style ? options.data.style : {},
          cols: options.data.cols ? options.data.cols : [],
          rows: options.data.rows ? options.data.rows : [],
        }
      : {
          style: {
            row: {},
            col: {},
            table: {},
          },
          cols: [
            { key: "Coluna 1", name: "Col 1" },
            { key: "Coluna 2", name: "Col 2" },
          ],
          rows: [
            {
              "Coluna 1": "Linha 1 Coluna 1",
              "Coluna 2": "Linha 1 Coluna 2",
              "Coluna 3": "Linha 1 Coluna 3",
            },
            {
              "Coluna 1": "Linha 2 Coluna 1",
              "Coluna 2": "Linha 2 Coluna 2",
              "Coluna 3": "Linha 2 Coluna 3",
            },
          ],
        }
  );

  const [bkpData, setBkpData] = useState({});

  let resTable = [];

  const style = {
    table: options.style?.table
      ? options.style.table
      : {
          width: "100%",
          height: "100%",
          maxheight: "90%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          padding: 5,

          borderRadius: 5,
          borderWidth: 20,
          border: "1px solid",
          borderColor: "rgba(100,100,100,0.2)",
          backgroundColor: "rgba(255,255,255,1)",

          overflowX: "hidden",
          overflowY: "auto",
        },
    row: options.style?.row
      ? options.style.row
      : {
          color: "rgba(0,0,0,1)",
          fontSize: "1.5vw",
          fontFamily: "Poppins",
          padding: 1,
          marginBottom: 1,
          minHeight: 30,
        },
    col: options.style?.col
      ? options.style.col
      : {
          fontSize: "2vw",
          color: "rgba(0,0,0,1)",
          fontFamily: "Poppins",
          padding: 1,
          marginBottom: 10,
          minHeight: 30,
        },
  };

  const loader = (options) => {
    if (!options) options = {};

    return {
      component: "container",
      style: options.style
        ? {
            ...style.row,
            width: "100%",
            borderRadius: 8,
            ...options.style,
            display: eleLoad ? "flex" : "none",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 999,
          }
        : {
            ...style.row,
            display: eleLoad ? "flex" : "none",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 999,
            width: "100%",
            borderRadius: 8,
            backgroundColor: "rgba(200,200,200,0.25)",
          },
      children: [
        {
          render: Loading({
            type: "custom",
            chars: options.chars ? options.chars : [1, 2, 3],
          }),
        },
      ],
    };
  };

  const loadTable = (info) => {
    const component = "container";

    let colNames = [];

    if (options.rowAction) console.log("colocar coluca de ações");

    Object.values(info.cols).map((col, i) => {
      resTable.push({
        component,
        style: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 5,
          margin: 5,
          width: "100%",

          borderRadius: 5,
          borderWidth: 20,
          border: "1px solid",
          borderColor: "rgba(100,100,100,0.4)",
          backgroundColor: "rgba(255,255,255,1)",
        },
        children: [
          loader({
            chars: col.name.split(""),
          }),
          {
            component: "text",
            text: col.name ? col.name : "vazio",
            style: col.style
              ? {
                  ...style.col,
                  ...col.style,
                  display: !eleLoad ? "flex" : "none",
                }
              : {
                  ...style.col,
                  display: !eleLoad ? "flex" : "none",
                },
          },
        ],
      });

      colNames.push({ name: col.key, index: i });
    });

    let limit = false;

    info.rows.map((row, i) => {
      if (i >= show.item) {
        limit = true;
        return 0;
      }

      colNames.map((cn) => {
        // se tiver action add final
        if (row[cn.name])
          resTable[cn.index].children.push({
            component,
            style: {
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            },
            children: [
              loader({
                chars: row[cn.name].split(""),
              }),
              {
                component: "text",
                text: row[cn.name],
                style: row.style
                  ? { ...row.style, display: !eleLoad ? "flex" : "none" }
                  : { ...style.row, display: !eleLoad ? "flex" : "none" },
              },
            ],
          });
      });
    });

    if (limit) {
      if (show.pagination) console.log("colocar paginacao");
      else console.log("Carregamento automatico em ", show.autoLoad, "%");
    } else console.log("Carregou tudo!");
  };

  useEffect(() => {
    (async () => {
      if (options.url) {
        const res = await privateHttp.get(options.url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token") || "Error",
          },
        });
        if (res.data) {
          setData(res.data.data);
          setBkpData(res.data.data);
        } else console.log("Table Get Error: ", data);

        // listen socket add or remove item , by index or key
        // let loads = [];
        // eleLoad.map((el) => {
        //   loads.push(false);
        // });

        await delay(2000);
        setEleLoad(false);
      }
    })();
  }, []);

  useEffect(async () => {
    setEleLoad(true);
    loadTable(data);
    await delay(1000);
    setEleLoad(false);
  }, [data]);

  loadTable(data);

  return {
    component: "container",
    style: style.table,
    children: [
      //Top Bar
      {
        component: "container",
        style: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          padding: 5,
          margin: 5,
        },
        children: [
          {
            component: "text",
            text: options.title,
            style: {
              display: options.title ? "flex" : "none",
              color: "rgba(0,0,0,1)",
              fontSize: "1.5vw",
              fontFamily: "Poppins",
            },
          },
          //pesquisa
          {
            component: "input",
            placeholder: "Digite para pesquisar",
            onChange: async (e) => {
              setEleLoad(true);
              if (e.target.value === "" || !e.target.value) setData(bkpData);
              else {
                setData({
                  ...data,
                  rows: bkpData.rows.filter((item) => {
                    let word = "";
                    options.search.map((w) => {
                      if (item[w]) word += item[w].toLowerCase();
                    });
                    return word.includes(e.target.value.toLowerCase());
                  }),
                });
              }
            },
            onBlur: async (e) => {},
            style: {
              display: options.search ? "flex" : "none",
              minHeight: "4vh",
              width: "40%",
              borderRadius: 5,
              color: "rgba(150,150,150,1)",
              fontSize: "1.5vw",
              fontFamily: "Poppins",
              borderWidth: 0.2,
              backgroundColor: "rgba(150,150,150,0.2)",
            },
          },
          // Action Area = Enviar Objeto pronto ...
          {
            component: "button",
            onClick: async (e) => {},
            text: "Adicionar Colaborador",
            style: {
              display: options.action ? "flex" : "none",
              fontSize: "1.5vw",
              fontFamily: "Poppins",
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 5,
              paddingBottom: 5,
              width: "auto",
              minHeight: "4vh",
              backgroundColor: theme.colors.pink3,
              color: "rgba(255,255,255,1)",
              borderRadius: 5,
              borderWidth: 0,
              textAlign: "center",
              alignItems: "center",
              cursor: "pointer",
            },
          },
        ],
      },
      //Rows
      {
        component: "container",
        style: {
          width: "100%",
          height: "95%",
          maxheight: "95%",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: 5,

          borderRadius: 5,
          borderWidth: 20,
          border: "1px solid",
          borderColor: "rgba(100,100,100,0.2)",
          backgroundColor: "rgba(255,0,255,0.1)",

          overflowX: "hidden",
          overflowY: "auto",
        },
        children: resTable,
      },
    ],
  };
};
