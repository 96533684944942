import React, { useEffect, useState } from "react";
import { Render } from "@8_dev/sjson-web";
import Theme from "../../theme";
import Navbar from "../../components/Navbar";
import FormRegisterService from "../../components/FormRegisterService";
import { useActive } from "../../context/activeContext";
import { useAuth } from "../../context/authContext";
import FormSchedule from "../../components/FormSchedule";

let ProviderServices = () => {
  const hours = [
    {
      label: "7:00",
      value: 7,
      enable: true,
    },
    {
      label: "8:00",
      value: 8,
      enable: true,
    },
    {
      label: "9:00",
      value: 9,
      enable: true,
    },
    {
      label: "10:00",
      value: 10,
      enable: true,
    },
    {
      label: "11:00",
      value: 11,
      enable: true,
    },
    {
      label: "12:00",
      value: 12,
      enable: true,
    },
    {
      label: "13:00",
      value: 13,
      enable: true,
    },
    {
      label: "14:00",
      value: 14,
      enable: true,
    },
    {
      label: "15:00",
      value: 15,
      enable: true,
    },
    {
      label: "16:00",
      value: 16,
      enable: true,
    },
    {
      label: "17:00",
      value: 17,
      enable: true,
    },
    {
      label: "18:00",
      value: 18,
      enable: true,
    },
    {
      label: "19:00",
      value: 19,
      enable: true,
    },
    {
      label: "20:00",
      value: 20,
      enable: true,
    },
    {
      label: "21:00",
      value: 21,
      enable: true,
    },
    {
      label: "22:00",
      value: 22,
      enable: true,
    },
    {
      label: "23:00",
      value: 23,
      enable: true,
    },
  ];
  const daysWeek = [
    {
      label: "Domingo",
      value: 0,
      enable: true,
      hours: hours,
    },
    {
      label: "Segunda",
      value: 1,
      enable: true,
      hours: hours,
    },
    {
      label: "Terça",
      value: 2,
      enable: true,
      hours: hours,
    },
    {
      label: "Quarta",
      value: 3,
      enable: true,
      hours: hours,
    },
    {
      label: "Quinta",
      value: 4,
      enable: true,
      hours: hours,
    },
    {
      label: "Sexta",
      value: 5,
      enable: true,
      hours: hours,
    },
    {
      label: "Sabádo",
      value: 6,
      enable: true,
      hours: hours,
    },
  ];

  const { active, updateActive } = useActive();
  const { user, setUser } = useAuth();

  const [hoursBath, setHoursBath] = useState(hours);
  const [daysWeekBath, setDaysWeekBath] = useState(daysWeek);
  const [hoursVet, setHoursVet] = useState(hours);
  const [daysWeekVet, setDaysWeekVet] = useState(daysWeek);
  const [hoursHotel, setHoursHotel] = useState(hours);
  const [daysWeekHotel, setDaysWeekHotel] = useState(daysWeek);

  useEffect(() => {
    (async () => {
      updateActive(2);
      let user = await localStorage.getItem("user");
      if (user) {
        let _user = JSON.parse(user);
      } else {
        //console.log("sem user");
      }
    })();
  }, []);

  return Render({
    component: "container",
    style: {
      width: "100vw",
      backgroundColor: Theme.colors.backgroundBlue,
      display: "flex",
      flexDirection: "column",
    },
    children: [
      Navbar({
        isAdmin: false,
        context: "provider",
        active: 2,
      }),
      {
        component: "container",
        style: {
          width: "100%",
          minHeight: "100vh",
        },
        children: [
          {
            render: (
              <FormSchedule
                hoursBath={hoursBath}
                hoursVet={hoursVet}
                hoursHotel={hoursHotel}
                setHoursBath={setHoursBath}
                setHoursVet={setHoursVet}
                setHoursHotel={setHoursHotel}
                daysWeekBath={daysWeekBath}
                daysWeekVet={daysWeekVet}
                daysWeekHotel={daysWeekHotel}
                setDaysWeekBath={setDaysWeekBath}
                setDaysWeekVet={setDaysWeekVet}
                setDaysWeekHotel={setDaysWeekHotel}
              />
            ),
          },
        ],
      },
    ],
  });
};

export default ProviderServices;
