import React, { useEffect, useState } from "react";
import { Render } from "@8_dev/sjson-web";
import theme from "../theme";
import api from "../service";
// import { useActive } from "../context/animalType";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CircularProgress from "@mui/material/CircularProgress";
import { sizeHeight } from "@mui/system";
import CurrencyInput from "react-currency-masked-input";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "./FormRegisterService.css";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";
import FolderImage from "../assets/images/folder.png";
import { Input } from "@mui/material";

import AddIcon from "../assets/images/add.svg";
import DeleteIcon from "../assets/images/delete.svg";
import DisableIcon from "../assets/images/disable.svg";
import EditIcon from "../assets/images/edit.svg";

import Select from "./select/select2";
import service from "../service";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

let FormRegisterServicee = ({ show }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (e) => {
      for (let file of e) {
        if (e.length && e[0].name.split(".")[1] === "xlsx") {
          const reader = new FileReader();
          reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet);
            // setNewCad({ ...newCad, file: json });
          };
          reader.readAsArrayBuffer(file);
          setInputFileLabel(
            " 🆗 [ " +
              file.name.split(".")[0] +
              " ] " +
              newCad.file.length +
              newCad.file.length ===
              1
              ? " colaborador encontrado!"
              : " colaboradores encontrados!"
          );
        } else {
          setInputFileLabel(" 😕 Arquivo inválido, seleciona outro.");
        }
      }
    },
  });
  const [pageView, setPageView] = useState(-1);
  const [newCad, setNewCad] = useState({});
  const [open, setOpen] = useState(false);

  const [servicesCategory, setServicesCategory] = useState([]);
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [animalSize, setAnimalSize] = useState([]);
  const [selectedAnimalSize, setSelectedAnimalSize] = useState("");
  const [serviceValue, setServiceValue] = useState(0);
  const [serviceDescription, setServiceDescription] = useState("");
  const [serviceID, setServiceID] = useState("");

  const [editInfoCategory, setEditInfoCategory] = useState("");

  // const { ctive, updateActive, services, setServices } = useActive();
  const [services, setServices] = useState([]);
  const [importServices, setImportServices] = useState([]);
  const [textSearch, setTextSearch] = useState("");
  const [filterServices, setFilterServices] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);

  let servicesData = [];
  const [serviceCategory, setServiceCategory] = useState({});
  const [ready, setReady] = useState(false);

  const [animalsType, setAnimalsType] = useState([]);
  const [animalType, setAnimalType] = useState("");
  const [animalTypeCode, setSelectedAnimalType] = useState("");

  const [inputFileLabel, setInputFileLabel] = useState(
    "Arraste ou clique para fazer o upload"
  );
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const pageStyle = {
    category: (item) => {
      return {
        width: "100%",
        fontSize: "18px",
        fontWeight: selectedCategory?._id === item._id ? "500" : "400",
        color: selectedCategory?._id === item._id ? "#011627" : "black",
        textDecorationLine:
          selectedCategory?._id === item._id ? "underline" : "none",
        backgroundColor:
          selectedCategory?._id === item._id ? "#F1F1F1" : "transparent",
        borderRadius: 4,
        padding: 4,
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "no-wrap",
        boxShadow:
          selectedCategory?._id === item._id
            ? "0px 0px 3px 1px  rgba(0,0,0,.4)"
            : "0px 0px 3px 1px  rgba(0,0,0,.1)",
      };
    },
  };

  const SelectCategory = Select({
    title: "Categoria",
    onChange: async (cat) => {
      setSelectedCategory(cat);
      let categoryList = await api.provider.getServicesOptionsSubcategory(
        cat._id
      );
      console.log(categoryList);
      let listSubCategory = [];
      categoryList.data.data.map((scat) => {
        listSubCategory.push({
          value: scat,
          key: scat._id,
          label: scat.name,
        });
      });
      setSubCategory(listSubCategory);
    },
    style: {
      box: {
        minWidth: "30%",
        maxWidth: "100%",
      },
    },
    item: category,
  });

  const SelectSubCategory = Select({
    title: "Serviços",
    onChange: async (ecat) => {
      setSelectedSubCategory(ecat);
    },
    style: {
      box: {
        minWidth: "30%",
        maxWidth: "100%",
      },
    },
    item: subCategory,
  });

  const SelectAnimalSize = Select({
    title: "Porte",
    onChange: async (size) => {
      setSelectedAnimalSize(size);
    },
    style: {
      box: {
        minWidth: "30%",
        maxWidth: "100%",
      },
    },
    item: animalSize,
  });

  useEffect(async () => {
    (async () => {
      let resAnimalType = await api.provider.getAnimalsType();
      setAnimalsType(resAnimalType.data.data);

      let categoryList = await api.provider.serviceCategory();
      setServicesCategory(categoryList.data.data);
      let listCategory = [];
      categoryList.data.data.map((cat) => {
        listCategory.push({
          value: cat,
          key: cat._id,
          label: cat.name,
          icon: cat.image,
        });
      });

      console.log(listCategory);
      setCategory(listCategory);

      let listServices = await api.provider.listService();

      let serviceFilter = {};
      let searchList = [];
      // const searchKey = Object.keys(listServices?.data?.data[0]);
      const searchKey = [
        "category",
        "animal_category",
        "description",
        "value",
        "size",
        "sub_category",
      ];

      await Promise.all(
        listServices.data.data.map(async (service) => {
          if (!service.animal_category._id) return;
          if (!serviceFilter[service.animal_category._id])
            serviceFilter[service.animal_category._id] = {
              [service.category._id]: {
                [service.sub_category]: [service],
              },
            };
          else if (
            !serviceFilter[service.animal_category._id][service.category._id]
          )
            serviceFilter[service.animal_category._id][service.category._id] = {
              [service.sub_category]: [service],
            };
          else if (
            !serviceFilter[service.animal_category._id][service.category._id][
              service.sub_category
            ]
          )
            serviceFilter[service.animal_category._id][service.category._id][
              service.sub_category
            ] = [service];
          else
            serviceFilter[service.animal_category._id][service.category._id][
              service.sub_category
            ].push(service);

          searchList.push({
            id: service._id,
            val: searchKey.map((k) => {
              if (service[k])
                return service[k].name
                  ? service[k].name
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                  : service[k]
                      .toString()
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "");
              else return "";
            }),
            item: service,
            key: searchKey,
          });
        })
      );

      setDataFilter(searchList);
      setServiceCategory(serviceFilter);
      setServices(listServices.data.data);
      servicesData = listServices.data.data;
      setReady(true);
    })();
  }, []);

  const searchItem = async (text, serachData) => {
    if (text.length === 0) {
      setFilterServices([]);
      return setTextSearch("");
    }

    text = text
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    setTextSearch(text);

    if (!text || text.length < 2) {
      // return;
    }

    let filterList = [""];

    await Promise.all(
      dataFilter.map(async (data) => {
        let found = false;
        await Promise.all(
          data.val.map(async (v) => {
            if (found) return;
            else if (v.startsWith(text)) found = true;
            else if (v.includes(text)) found = true;
          })
        );
        if (found) filterList.push(data.id);
      })
    );

    setFilterServices(filterList);
  };

  return (
    <div
      style={{
        width: "100%",
        padding: "2% 10%",
        marginTop: 80,
      }}
    >
      <Snackbar open={open} autoHideDuration={1500} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Salvo com sucesso!
        </Alert>
      </Snackbar>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          flex: 1,
        }}
      >
        <p
          style={{
            color: theme.colors.darkGreen,
            fontSize: 28,
            fontFamily: "Poppins",
          }}
        ></p>

        <div
          style={{
            display: pageView < 0 ? "flex" : "none",
            alignItems: "center",
            marginLeft: 80,
          }}
        >
          {animalsType.map((type) => {
            return (
              <button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border:
                    type._id == animalType._id
                      ? "1px solid #2EC4B6"
                      : "1px solid #D2D2D2",
                  marginRight: 20,
                  borderRadius: 8,
                  fontFamily: "Poppins",
                  padding: "8px 16px",
                  background:
                    type._id == animalType._id
                      ? "rgba(46, 196, 182, 0.3)"
                      : theme.colors.backgroundBlue,
                  cursor: "pointer",
                }}
                onClick={async () => {
                  setPageView(-1);
                  setSelectedAnimalSize("");
                  setSelectedCategory("");
                  setSelectedSubCategory("");
                  setSelectedAnimalSize("");
                  setServiceValue("");
                  if (animalType._id === type._id) setAnimalType("");
                  else setAnimalType(type);
                }}
              >
                <img
                  src={type.image}
                  style={{ width: 35, height: 35, marginRight: 15 }}
                />
                <span
                  style={{
                    fontSize: 20,
                    color: "#011627",
                    fontFamily: "Poppins",
                  }}
                >
                  {type.name}
                </span>
              </button>
            );
          })}
        </div>

        <div
          style={{
            flex: 1,
          }}
        >
          {Render({
            component: "container",
            style: {
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              padding: "1%",
            },
            children: [
              //Text
              {
                component: "text",
                text: "",
                style: {
                  color: "rgba(0,0,0,1)",
                  fontSize: 14,
                  fontWeight: 500,
                  fontFamily: "Poppins",
                },
              },
              // content
              {
                component: "container",
                style: {
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "flex-start",
                },
                children: [
                  //menu
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      alignItems: "flex-end",
                      justifyContent: "space-between",
                      width: "100%",
                      flex: 1,
                    },
                    children: [
                      {
                        component: "button",
                        text: "<- Voltar",
                        onClick: async (e) => {
                          setAnimalType("");
                          setSelectedAnimalSize("");
                          setSelectedCategory("");
                          setSelectedSubCategory("");
                          setSelectedAnimalSize("");
                          setServiceValue("");
                          await SelectCategory.setValue("");
                          await SelectSubCategory.setValue("");
                          await SelectAnimalSize.setValue("");
                          setPageView(-1);
                          setServiceID("");
                        },
                        style: {
                          fontFamily: "Poppins",
                          fontSize: 12,
                          padding: 8,
                          height: 30,
                          marginRight: 100,
                          cursor: "pointer",
                          borderRadius: "4px",
                          border: "1px solid",
                          borderColor: "rgba(255,100,100,0.4)",
                          display: pageView >= 0 ? "flex" : "none",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                      },
                      {
                        component: "button",
                        text: "Adicionar por lote",
                        onClick: async (e) => {
                          setServices([]);
                          setAnimalType("");
                          setSelectedAnimalSize("");
                          setSelectedCategory("");
                          setSelectedSubCategory("");
                          setSelectedAnimalSize("");
                          setServiceValue("");
                          await SelectCategory.setValue("");
                          await SelectSubCategory.setValue("");
                          await SelectAnimalSize.setValue("");
                          if (pageView === 1) setPageView(-1);
                          else setPageView(1);
                          setServiceID("");
                        },
                        style: {
                          fontFamily: "Poppins",
                          fontSize: 12,
                          fontWeight: pageView === 1 ? "bold" : 300,
                          color:
                            pageView === 1 ? "#000" : "rgba(157, 157, 157, 1)",
                          height: 30,
                          width: "auto",
                          cursor: "pointer",
                          borderRadius: "4px",
                          border: "1px solid",
                          // display: pageView === 1 ? "flex" : "none",
                          backgroundColor:
                            pageView === 1
                              ? "rgba(210, 240, 237, 1)"
                              : "rgba(255, 226, 217, 0)",
                          borderColor:
                            pageView === 1
                              ? "rgba(125, 226, 217, 1)"
                              : "rgba(157, 157, 157, 1)",
                        },
                      },
                      // 0 1 2 3 4 5 6 7 8 9 A B C D E F
                      {
                        component: "button",
                        text: "Adicionar manualmente",
                        onClick: async (e) => {
                          setAnimalType("");
                          setAnimalType("");
                          setSelectedAnimalSize("");
                          setSelectedCategory("");
                          setSelectedSubCategory("");
                          setSelectedAnimalSize("");
                          setServiceValue("");
                          await SelectCategory.setValue("");
                          await SelectSubCategory.setValue("");
                          await SelectAnimalSize.setValue("");
                          if (pageView === 0) setPageView(-1);
                          else setPageView(0);
                          setServiceID("");
                        },
                        style: {
                          marginLeft: 10,
                          fontFamily: "Poppins",
                          fontSize: 12,
                          fontWeight: pageView === 0 ? "bold" : 300,
                          color:
                            pageView === 0 ? "#000" : "rgba(157, 157, 157, 1)",
                          height: 30,
                          width: "auto",
                          cursor: "pointer",
                          borderRadius: "4px",
                          border: "1px solid",
                          // display: pageView === 0 ? "flex" : "none",
                          backgroundColor:
                            pageView === 0
                              ? "rgba(210, 240, 237, 1)"
                              : "rgba(255, 226, 217, 0)",
                          borderColor:
                            pageView === 0
                              ? "rgba(125, 226, 217, 1)"
                              : "rgba(157, 157, 157, 1)",
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          })}
        </div>
      </div>

      {/* Mnual ADD */}
      {Render({
        component: "container",
        style: {
          display: pageView === 0 ? "flex" : "none",
          flexDirection: "column",
          flexWrap: "wrap",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: "1%",
          width: "100%",
          marginTop: "4%",
          borderRadius: 8,
          borderWidth: 0.1,
          borderStyle: "solid",
          borderColor: "rgba(100,100,100, 0.2)",
        },
        children: [
          //Animal Type List
          {
            component: "container",
            style: {
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: 8,
              flex: 1,
              width: "90%",
            },
            children: [
              {
                component: "text",
                text: "Selecione a espécie",
                style: {
                  color: "rgba(0,0,0,1)",
                  fontSize: 14,
                  fontWeight: 500,
                  fontFamily: "Poppins",
                  width: "20%",
                },
              },
              {
                render: (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flex: 1,
                      gap: 30,
                    }}
                  >
                    {animalsType.map((type) => {
                      return (
                        <button
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border:
                              type._id == animalType._id
                                ? "1px solid #2EC4B6"
                                : "1px solid #D2D2D2",
                            borderRadius: 8,
                            fontFamily: "Poppins",
                            padding: "8px 16px",
                            background:
                              type._id == animalType._id
                                ? "rgba(46, 196, 182, 0.3)"
                                : theme.colors.backgroundBlue,
                            cursor: "pointer",
                          }}
                          onClick={async () => {
                            setAnimalType(type);

                            if (type.code === 1) {
                              let animalSizeList =
                                await api.provider.getPetSize(type._id);
                              let listAnimalSize = [];
                              animalSizeList.data.data.map((size) => {
                                listAnimalSize.push({
                                  value: size,
                                  key: size._id,
                                  label: size.name,
                                  icon: size.image,
                                });
                              });
                              setAnimalSize(listAnimalSize);
                            }
                          }}
                        >
                          <img
                            src={type.image}
                            style={{ width: 35, height: 35, marginRight: 15 }}
                          />
                          <span
                            style={{
                              fontSize: 20,
                              color: "#011627",
                              fontFamily: "Poppins",
                            }}
                          >
                            {type.name}
                          </span>
                        </button>
                      );
                    })}
                  </div>
                ),
              },
            ],
          },

          //category
          {
            component: "container",
            style: {
              display: animalType._id ? "flex" : "none",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: 8,
              flex: 1,
              width: "90%",
            },
            children: [
              {
                component: "text",
                text: "Selecione a Categoria",
                style: {
                  color: "rgba(0,0,0,1)",
                  fontSize: 14,
                  fontWeight: 500,
                  fontFamily: "Poppins",
                  width: "20%",
                  marginRight: "3%",
                },
              },
              { render: SelectCategory.component },

              // {
              //   render: (
              //     <Select.component
              //       title="Categoria"
              //       value={selectedCategory._id}
              //       onChange={async (cat) => {
              //         setSelectedCategory(cat);
              //         let categoryList =
              //           await api.provider.getServicesOptionsSubcategory(
              //             cat._id
              //           );
              //         let listSubCategory = [];
              //         categoryList.data.data.map((scat) => {
              //           listSubCategory.push({
              //             value: scat,
              //             label: scat.name,
              //           });
              //         });
              //         setSubCategory(listSubCategory);
              //       }}
              //       style={{
              //         display: "block",
              //         box: {
              //           minWidth: "30%",
              //           maxWidth: "100%",
              //         },
              //       }}
              //       item={category}
              //     />
              //   ),
              // },
            ],
          },

          //sub category
          {
            component: "container",
            style: {
              display: animalType._id ? "flex" : "none",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: 8,
              flex: 1,
              width: "90%",
            },
            children: [
              {
                component: "text",
                text: "Selecione o Serviço",
                style: {
                  color: "rgba(0,0,0,1)",
                  fontSize: 14,
                  fontWeight: 500,
                  fontFamily: "Poppins",
                  width: "20%",
                  marginRight: "3%",
                },
              },
              { render: SelectSubCategory.component },

              // {
              //   render: (
              //     <Select.component
              //       title="Sub Categoria"
              //       value={selectedSubCategory}
              //       default={selectedSubCategory}
              //       onChange={async (ecat) => {
              //         setSelectedSubCategory(ecat);
              //       }}
              //       style={{
              //         box: {
              //           minWidth: "30%",
              //           maxWidth: "100%",
              //         },
              //       }}
              //       item={subCategory}
              //     />
              //   ),
              // },
            ],
          },

          //porte
          {
            component: "container",
            style: {
              display:
                animalType.code && animalType.code === 1 ? "flex" : "none",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: 8,
              flex: 1,
              width: "90%",
            },
            children: [
              {
                component: "text",
                text: "Selecione o Porte",
                style: {
                  color: "rgba(0,0,0,1)",
                  fontSize: 14,
                  fontWeight: 500,
                  fontFamily: "Poppins",
                  width: "20%",
                  marginRight: "3%",
                },
              },
              {
                render: SelectAnimalSize.component,
              },
            ],
          },

          // valor
          {
            component: "container",
            style: {
              display: animalType._id ? "flex" : "none",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: 8,
              flex: 1,
              width: "90%",
            },
            children: [
              {
                component: "text",
                text: "Valor do Serviço",
                style: {
                  color: "rgba(0,0,0,1)",
                  fontSize: 14,
                  fontWeight: 500,
                  fontFamily: "Poppins",
                  width: "20%",
                },
              },
              //input
              {
                render: (
                  <div
                    style={{
                      display: "flex",
                      marginLeft: 0,
                      flex: 1,
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: 30,
                        fontFamily: "Poppins",
                        marginRight: "1%",
                      }}
                    >
                      {" "}
                      R${" "}
                    </p>
                    <CurrencyInput
                      value={serviceValue}
                      onChange={async (e, value) => {
                        setServiceValue(value);
                      }}
                      style={{
                        width: 220,
                        height: 50,
                        borderRadius: 4,
                        color: "rgba(100,100,100,1)",
                        fontSize: 24,
                        fontFamily: "Poppins",
                        borderWidth: 0,
                        backgroundColor: "rgba(100,100,100,0.2)",
                        paddingLeft: "1%",
                      }}
                    />
                  </div>
                ),
              },
            ],
          },

          // desc
          {
            component: "container",
            style: {
              display: animalType._id ? "flex" : "none",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: 8,
              flex: 1,
              width: "90%",
            },
            children: [
              {
                component: "text",
                text: "Descrição",
                style: {
                  color: "rgba(0,0,0,1)",
                  fontSize: 14,
                  fontWeight: 500,
                  fontFamily: "Poppins",
                  width: "20%",
                },
              },
              //input
              {
                render: (
                  <Input
                    value={serviceDescription}
                    autoComplete
                    onChange={async (e) => {
                      setServiceDescription(e.target.value);
                    }}
                    style={{
                      flex: 1,
                      height: 50,
                      borderRadius: 4,
                      color: "rgba(100,100,100,1)",
                      fontSize: 16,
                      fontFamily: "Poppins",
                      borderWidth: 0,
                      borderColor: "transparent",
                      paddingLeft: 8,
                      backgroundColor: "rgba(100,100,100,0.2)",
                    }}
                  />
                ),
              },
              // {
              //   component: "input",
              //   placeholder: "",
              //   onChange: async (e) => {
              //     setServiceDescription(e.target.value);
              //   },
              //   onBlur: async (e) => {},
              //   style: {
              //     flex: 1,
              //     height: 50,
              //     borderRadius: 4,
              //     color: "rgba(100,100,100,1)",
              //     fontSize: 16,
              //     fontFamily: "Poppins",
              //     borderWidth: 0,
              //     backgroundColor: "rgba(100,100,100,0.2)",
              //   },
              //   value: serviceDescription,
              // },
            ],
          },

          // salvar
          {
            component: "container",
            style: {
              display:
                animalType.code === 1
                  ? parseFloat(serviceValue) > 0 &&
                    selectedCategory?._id &&
                    selectedSubCategory?._id &&
                    selectedAnimalSize?._id &&
                    parseFloat(serviceValue) > 0
                    ? "flex"
                    : "none"
                  : parseFloat(serviceValue) > 0 &&
                    selectedCategory?._id &&
                    selectedSubCategory?._id &&
                    parseFloat(serviceValue) > 0
                  ? "flex"
                  : "none",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              flex: 1,
              width: "90%",
              marginTop: "2%",
            },
            children: [
              {
                component: "button",
                text: serviceID !== "" ? "Editar Serviço" : "Adicionar Serviço",
                style: {
                  cursor: "pointer",
                  color: "#fff",
                  fontSize: 14,
                  fontWeight: 500,
                  fontFamily: "Poppins",
                  padding: 8,
                  backgroundColor: theme.colors.pink1,
                  borderRadius: 4,
                  borderWidth: 0,
                },

                onClick: async () => {
                  let data = await api.provider.addService({
                    [serviceID !== "" ? "_id" : ""]: serviceID,
                    animal_category: animalType._id,
                    category: selectedCategory?._id,
                    sub_category: selectedSubCategory._id,
                    size: selectedAnimalSize._id || "",
                    value: parseFloat(serviceValue),
                    description: serviceDescription,
                  });

                  if (data.data) data = data.data;

                  if (data._id) {
                    setSelectedCategory("");
                    setSelectedSubCategory("");
                    setSelectedAnimalSize("");
                    setServiceValue("");
                    setServiceDescription("");
                    // setAnimalType("");

                    await SelectCategory.setValue("");
                    await SelectSubCategory.setValue("");
                    await SelectAnimalSize.setValue("");

                    if (serviceID !== "") {
                      const pos = services.map((e) => e._id).indexOf(serviceID);
                      services[pos] = {
                        _id: serviceID,
                        animal_category: animalType,
                        category: selectedCategory,
                        sub_category: selectedSubCategory,
                        size: selectedAnimalSize || "",
                        value: parseFloat(serviceValue),
                        description: serviceDescription,
                      };
                      setPageView(-1);
                    } else {
                      // setServices(
                      // services.concat([
                      //   {
                      //     _id: data._id,
                      //     animal_category: animalType,
                      //     category: selectedCategory,
                      //     sub_category: selectedSubCategory,
                      //     size: selectedAnimalSize || "",
                      //     value: parseFloat(serviceValue),
                      //     description: serviceDescription,
                      //   },
                      //   ])
                      // );
                      console.log(
                        services.concat([
                          {
                            _id: data._id,
                            animal_category: animalType,
                            category: selectedCategory,
                            sub_category: selectedSubCategory,
                            size: selectedAnimalSize || "",
                            value: parseFloat(serviceValue),
                            description: serviceDescription,
                          },
                        ])
                      );
                    }
                    setServiceID("");
                  }
                },
              },
            ],
          },
        ],
      })}

      {/* File Up */}
      {Render({
        component: "container",
        style: {
          display: pageView === 1 ? "flex" : "none",
          flexDirection: "column",
          flexWrap: "wrap",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: "1%",
          width: "100%",
          marginTop: "4%",
          borderRadius: 8,
          borderWidth: 0.1,
          borderStyle: "solid",
          borderColor: "rgba(100,100,100, 0.2)",
        },
        children: [
          //top
          {
            component: "container",
            style: {
              display: pageView === 1 ? "flex" : "none",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              width: "100%",
            },
            children: [
              //Text
              {
                component: "text",
                text: "Faça o upload da planilha com os serviços que deseja adicionar.",
                style: {
                  color: "rgba(0,0,0,1)",
                  fontSize: 14,
                  fontWeight: 500,
                  fontFamily: "Poppins",
                  flex: 1,
                },
              },

              // import
              {
                component: "container",
                style: {
                  display: importServices.length > 0 ? "flex" : "none",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                },
                children: [
                  {
                    component: "button",
                    text: "Importar",
                    style: {
                      cursor: "pointer",
                      color: "#fff",
                      fontSize: 14,
                      fontWeight: 500,
                      fontFamily: "Poppins",
                      padding: 8,
                      backgroundColor: theme.colors.pink1,
                      borderRadius: 4,
                      borderWidth: 0,
                    },

                    onClick: async () => {
                      let importResult = [];
                      let serviceResult = [];

                      await Promise.all(
                        importServices.map(async (service) => {
                          // console.log(service);

                          let data = await api.provider.addService(service);
                          if (data.data) data = data.data;

                          if (data._id) {
                            console.log("success");
                            service.status = 1;
                          } else {
                            console.log("erro", data.error);
                            service.status = -1;
                            service.error = data.error;
                          }
                          importResult[service.id] = service;
                        })
                      );

                      setImportServices(importResult);
                    },
                  },
                ],
              },
            ],
          },
          //rules
          {
            component: "container",
            style: {
              flexDirection: "column",
              flexWrap: "wrap",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "2%",
              marginTop: "1%",
              display: "none",
            },
            children: [
              {
                component: "text",
                text: "Regras de importacao",
                style: {
                  color: "rgba(0,0,0,1)",
                  fontSize: 16,
                  fontWeight: 500,
                  fontFamily: "Poppins",
                },
              },
              {
                component: "text",
                text: "Somente campo descrição nao e obrigatorio.",
                style: {
                  color: "rgba(0,0,0,1)",
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "Poppins",
                  padding: 10,
                },
              },
              {
                component: "text",
                text: "Somente campo descrição nao e obrigatorio.",
                style: {
                  color: "rgba(0,0,0,1)",
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "Poppins",
                  padding: 10,
                },
              },
            ],
          },

          // download model
          {
            component: "button",
            onClick: async () => {
              api.download.sheetModel("modelo_cadastro_servico.xlsx");
            },
            text: "Baixa aqui o modelo de planilha.",
            style: {
              color: theme.colors.pink1,
              fontSize: 14,
              fontWeight: "bold",
              fontFamily: "Poppins",
              textDecoration: "underline",
              marginTop: "2%",
              cursor: "pointer",
            },
          },

          // upload
          {
            render: (
              <div
                {...getRootProps()}
                style={{
                  width: "100%",
                  height: 200,
                  background: "#EFF0F7",
                  borderRadius: 10,
                  borderWidth: 2,
                  borderStyle: "dashed",
                  borderColor: theme.colors.lightGreen,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  marginTop: "3%",
                }}
              >
                <img src={FolderImage} />
                <input
                  {...getInputProps()}
                  id="upload-file"
                  type="file"
                  style={{ display: "none" }}
                  onChange={async (e) => {
                    // setNewCad({ ...newCad, file: [] });
                    e.preventDefault();
                    if (
                      e.target.files.length &&
                      e.target.files[0].name.split(".")[1] === "xlsx"
                    ) {
                      const reader = new FileReader();
                      reader.onload = async (e) => {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: "array" });

                        const resultKeys = Object.keys(workbook.Sheets);
                        let resultInfo = {};
                        let resultServices = [];
                        let listServices = [];

                        let subCategoryList =
                          await api.provider.getServicesOptionsSubcategory();
                        let animalSizeList = await api.provider.getPetSize();

                        // console.log(category);
                        // console.log(subCategoryList.data.data);
                        // console.log(animalSizeList.data.data);

                        const defaultValue = {
                          animalCategory: {},
                          category: {},
                          subCategory: {},
                          size: {},
                        };

                        Promise.all(
                          animalsType.map(async (type) => {
                            if (type._id && type.name)
                              defaultValue.animalCategory[type.name] = type._id;
                          }),

                          servicesCategory.map(async (cat) => {
                            if (cat._id && cat.name)
                              defaultValue.category[
                                cat.name
                                  .normalize("NFD")
                                  .replace(/[\u0300-\u036f]/g, "")
                                  .replace(/[^\w\s]/gi, "")
                                  .replace(/\s+/g, "_")
                              ] = cat._id;
                          }),

                          subCategoryList.data.data.map(async (sCat) => {
                            if (sCat._id && sCat.name)
                              defaultValue.subCategory[
                                sCat.name
                                  .normalize("NFD")
                                  .replace(/[\u0300-\u036f]/g, "")
                                  .replace(/[^\w\s]/gi, "")
                                  .replace(/\s+/g, "_")
                              ] = sCat._id;
                          }),

                          animalSizeList.data.data.map(async (size) => {
                            if (size._id && size.name)
                              defaultValue.size[
                                size.name
                                  .normalize("NFD")
                                  .replace(/[\u0300-\u036f]/g, "")
                                  .replace(/[^\w\s]/gi, "")
                                  .replace(/\s+/g, "_")
                              ] = size._id;
                          })
                        );

                        console.log(defaultValue);

                        resultKeys.map((k) => {
                          resultInfo[k] = XLSX.utils.sheet_to_json(
                            workbook.Sheets[k]
                          );
                        });

                        if (resultInfo["servicos"]) {
                          let searchList = [];
                          const searchKey = [
                            "Tipo",
                            "Categoria",
                            "Serviço",
                            "Valor",
                            "Descrição",
                            "Porte",
                          ];

                          resultInfo["servicos"].map((service, i) => {
                            // obter nome do obj pelo name

                            const catName = service["Categoria"]
                              .normalize("NFD")
                              .replace(/[\u0300-\u036f]/g, "")
                              .replace(/[^\w\s]/gi, "")
                              .replace(/\s+/g, "_");

                            const serviceName = service["Serviço"]
                              .normalize("NFD")
                              .replace(/[\u0300-\u036f]/g, "")
                              .replace(/[^\w\s]/gi, "")
                              .replace(/\s+/g, "_");

                            const sizeName = service["Porte"]
                              .normalize("NFD")
                              .replace(/[\u0300-\u036f]/g, "")
                              .replace(/[^\w\s]/gi, "")
                              .replace(/\s+/g, "_");

                            if (
                              defaultValue.animalCategory[service["Tipo"]] &&
                              defaultValue.category[catName] &&
                              defaultValue.subCategory[serviceName] &&
                              defaultValue.size[sizeName]
                            ) {
                              listServices.push({
                                id: i.toString(),
                                animal_category:
                                  defaultValue.animalCategory[service["Tipo"]],
                                category: defaultValue.category[catName], // obter ID
                                sub_category:
                                  defaultValue.subCategory[serviceName],
                                size: defaultValue.size[sizeName],
                                value: parseFloat(service["Valor"]),
                                description: service["Descrição"],
                                status: 0,
                              });
                            } else {
                              // ativar erro na celula de campo invalido
                              // contagem de error,
                              // e selet mostrar errors

                              console.log(
                                "NOT FOUND",
                                catName,
                                serviceName,
                                sizeName
                              );
                            }

                            resultServices.push({
                              animal_category: {
                                name: service["Tipo"],
                              },

                              category: {
                                name: service["Categoria"],
                              },

                              sub_category: {
                                name: service["Serviço"],
                              },

                              size: {
                                name: service["Porte"],
                              },

                              description: service["Descrição"],

                              value: service["Valor"],
                            });

                            searchList.push({
                              id: i.toString(),
                              val: searchKey.map((k) => {
                                if (service[k])
                                  return service[k]
                                    .toString()
                                    .toLowerCase()
                                    .normalize("NFD")
                                    .replace(/[\u0300-\u036f]/g, "");
                                else return "";
                              }),
                              item: service,
                              key: searchKey,
                            });
                          });

                          setDataFilter(searchList);
                          setImportServices(listServices);
                          setServices(resultServices);
                        }

                        // const sheetName = workbook.SheetNames[0];
                        // const worksheet = workbook.Sheets[sheetName];
                        // const json = XLSX.utils.sheet_to_json(worksheet);

                        // setNewCad({ ...newCad, file: json });
                      };
                      reader.readAsArrayBuffer(e.target.files[0]);
                      setInputFileLabel(
                        " 🆗 [ " +
                          e.target.files[0].name.split(".")[0] +
                          " ] carregado! "
                      );
                    } else
                      setInputFileLabel(
                        " 😕 Arquivo inválido, seleciona outro."
                      );
                  }}
                />
                <p
                  style={{
                    color: theme.colors.darkBlue,
                    fontSize: 20,
                    fontFamily: "Poppins",
                    marginBottom: 5,
                    marginTop: 20,
                  }}
                >
                  {inputFileLabel}
                </p>
              </div>
            ),
          },
        ],
      })}

      {/* Result */}
      <div style={{}}>
        {Render({
          component: "container",
          style: {
            display:
              (pageView >= 0 && animalType !== "") ||
              pageView < 0 ||
              pageView === 1
                ? "flex"
                : "none",
            flexDirection: "row",
            flexWrap: "nowrap",
            gap: 16,
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: "1%",
            width: "100%",
            maxHeight: "100vh",
            marginTop: "4%",
            borderRadius: 8,
            borderWidth: 0.1,
            borderStyle: "solid",
            borderColor: "rgba(100,100,100, 0.2)",
          },
          children: [
            // category list
            {
              component: "container",
              style: {
                display: pageView < 0 ? "flex" : "none",
                flexDirection: "column",
                flexWrap: "wrap",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                borderRadius: 8,
                borderWidth: 0.1,
                borderStyle: "solid",
                borderColor: "rgba(100,100,100, 0.6)",
                padding: 8,
                gap: 10,
              },
              children: category.map((item, index) => {
                if (item.value.code === 999)
                  return {
                    component: "text",
                    style: { display: "none" },
                  };
                return {
                  render: (
                    <div
                      onClick={() => {
                        if (selectedCategory?._id === item.value._id)
                          setSelectedCategory("");
                        else setSelectedCategory(item.value);
                      }}
                      style={pageStyle.category(item.value)}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          flexDirection: "row",
                          flex: 1,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flex: 1,
                            width: "100%",
                          }}
                        >
                          <img
                            src={item.value.image}
                            style={{
                              width: "40px",
                              maxWidth: "35%",
                              margin: "1%",
                            }}
                          />
                          <p style={{ marginLeft: "2%", flex: 1 }}>
                            {item.value.name}
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          width: 30,
                          height: 30,
                          backgroundColor: "rgba(100,100,100,0.1)",
                          borderRadius: 20,
                          alignItems: "center",
                          justifyContent: "center",
                          padding: 8,
                          fontSize: 16,
                          display: serviceCategory?.animalType
                            ? "flex"
                            : "none",
                        }}
                      ></div>
                    </div>
                  ),
                };
              }),
            },

            // table
            {
              component: "container",
              style: {
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                overflow: "hidden",
              },
              children: [
                // top
                {
                  component: "container",
                  style: {
                    display: "flex",
                    width: "94%",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    overflow: "hidden",
                    padding: 16,
                  },
                  children: [
                    // title
                    {
                      component: "container",
                      style: {
                        color: "rgba(0,0,0,1)",
                        fontSize: 24,
                        fontWeight: 600,
                        fontFamily: "Poppins",
                      },
                      text: "Lista de Serviços ",
                    },

                    //input
                    {
                      render: (
                        <Input
                          value={textSearch}
                          autoComplete
                          placeholder="Digite para buscar"
                          onChange={async (e) => {
                            searchItem(e.target.value);
                          }}
                          style={{
                            width: 300,
                            height: 50,
                            borderRadius: 4,
                            color: "rgba(100,100,100,1)",
                            fontSize: 16,
                            fontFamily: "Poppins",
                            borderWidth: 0,
                            borderColor: "transparent",
                            paddingLeft: 8,
                            backgroundColor: "rgba(100,100,100,0.2)",
                          }}
                        />
                      ),
                    },
                  ],
                },

                // cool names
                {
                  component: "container",
                  style: {
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    overflow: "hidden",
                    marginTop: 32,
                    marginBottom: 4,
                    padding: 8,
                    borderRadius: 8,
                    backgroundColor: "rgba(255,255,255,1)",
                  },
                  children: [
                    // A
                    {
                      component: "container",
                      style: {
                        display: "flex",
                        flexDirection: "row",
                        flex: 1,
                      },
                      children: [
                        {
                          component: "text",
                          text: "Espécie",
                          style: {
                            color: "rgba(0,0,0,1)",
                            fontSize: 16,
                            fontWeight: 600,
                            fontFamily: "Poppins",
                            marginRight: 4,
                            flex: 1,
                          },
                        },
                        {
                          component: "text",
                          text: "Categoria",
                          style: {
                            color: "rgba(0,0,0,1)",
                            fontSize: 16,
                            fontWeight: 600,
                            fontFamily: "Poppins",
                            flex: 1,
                            marginRight: 4,
                          },
                        },
                        {
                          component: "text",
                          text: "Serviço",
                          style: {
                            color: "rgba(0,0,0,1)",
                            fontSize: 16,
                            fontWeight: 600,
                            fontFamily: "Poppins",
                            flex: 1,
                            marginRight: 4,
                          },
                        },
                      ],
                    },
                    // B
                    {
                      component: "container",
                      style: {
                        display: "flex",
                        flexDirection: "row",
                        flex: 1,
                      },
                      children: [
                        {
                          component: "text",
                          text: "Descrição",
                          style: {
                            color: "rgba(0,0,0,1)",
                            fontSize: 16,
                            fontWeight: 600,
                            fontFamily: "Poppins",
                            inlineSize: 200,
                            overflowWrap: "break-word",
                            textAlign: "center",
                            minWidth: "15%",
                            flex: 1,
                            marginRight: 4,
                          },
                        },

                        {
                          component: "text",
                          text: "Porte",
                          style: {
                            color: "rgba(0,0,0,1)",
                            fontSize: 16,
                            fontWeight: 600,
                            fontFamily: "Poppins",
                            minWidth: "10%",
                            // maxWidth: "10%",
                            flex: 1,
                            marginRight: 4,
                          },
                        },

                        {
                          component: "text",
                          text: "Valor R$",
                          style: {
                            color: "rgba(0,0,0,1)",
                            fontSize: 16,
                            fontWeight: 600,
                            fontFamily: "Poppins",
                            minWidth: "8%",
                            flex: 1,
                            marginRight: 4,
                            textAlign: "center",
                          },
                        },
                      ],
                    },

                    // action
                    {
                      component: "container",
                      style: {
                        display: pageView === 1 ? "none" : "flex",
                        flex: 0.4,
                        flexDirection: "row",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                        minWidth: "8%",
                        marginRight: 4,
                      },
                      children: [],
                    },
                  ],
                },

                // table list
                {
                  component: "container",
                  style: {
                    display: "flex",
                    width: "100%",
                    flex: 1,
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",

                    maxHeight: 500,
                    overflow: "hidden",
                    overflowY: "scroll",
                    paddingRight: 8,
                  },
                  children: services.map((service, i) => {
                    console.log("verService", service);
                    if (
                      (animalType._id &&
                        animalType._id !== service.animal_category?._id) ||
                      (selectedCategory?._id &&
                        selectedCategory?._id !== service.category?._id) ||
                      (selectedSubCategory?._id &&
                        selectedSubCategory?._id !== service.sub_category?._id)
                    )
                      return {
                        component: "text",
                        style: { display: "none" },
                      };

                    return {
                      component: "container",
                      style: {
                        display:
                          filterServices.length === 0 ||
                          filterServices.includes(
                            service._id
                              ? service._id
                              : service.id
                              ? service.id
                              : i.toString()
                          )
                            ? "flex"
                            : "none",
                        width: "100%",
                        minHeight: 50,
                        maxHeight: 70,
                        flexDirection: "row",
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: 8,
                        borderWidth: 0.1,
                        borderStyle: "solid",
                        borderColor: "rgba(100,100,100, 0.2)",
                        backgroundColor: importServices[i]
                          ? importServices[i].status === 1
                            ? "rgba(100, 200, 100, 0.2)"
                            : importServices[i].status === -1
                            ? "rgba(200, 100, 100, 0.2)"
                            : serviceID === service._id
                            ? "rgba(100,0,100, 0.1)"
                            : "rgba(100,100,100, 0.05)"
                          : "rgba(100,100,100, 0.05)",
                        padding: 4,
                        margin: 4,
                        gap: 8,
                      },
                      children: [
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            width: "100%",
                            height: 50,
                            minHeight: 50,
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "center",
                            justifyContent: "space-between",
                          },
                          children: [
                            // A
                            {
                              component: "container",
                              style: {
                                display: "flex",
                                flexDirection: "row",
                                flex: 1,
                              },
                              children: [
                                {
                                  component: "text",
                                  text: service.animal_category.name,
                                  style: {
                                    color: "rgba(0,0,0,1)",
                                    fontSize: 12,
                                    fontWeight: 400,
                                    fontFamily: "Poppins",
                                    marginRight: 4,
                                    flex: 1,
                                  },
                                },
                                {
                                  component: "text",
                                  text: service.category.name,
                                  style: {
                                    color: "rgba(0,0,0,1)",
                                    fontSize: 12,
                                    fontWeight: 400,
                                    fontFamily: "Poppins",
                                    flex: 1,
                                    marginRight: 4,
                                  },
                                },
                                {
                                  component: "text",
                                  text: service.sub_category?.name,
                                  style: {
                                    color: "rgba(0,0,0,1)",
                                    fontSize: 12,
                                    fontWeight: 400,
                                    fontFamily: "Poppins",
                                    flex: 1,
                                    marginRight: 4,
                                  },
                                },
                              ],
                            },
                            // B
                            {
                              component: "container",
                              style: {
                                display: "flex",
                                flexDirection: "row",
                                flex: 1,
                              },
                              children: [
                                {
                                  component: "text",
                                  text: service.description,
                                  style: {
                                    color: "rgba(0,0,0,1)",
                                    fontSize: 12,
                                    fontWeight: 400,
                                    fontFamily: "Poppins",
                                    inlineSize: 200,
                                    overflowWrap: "break-word",
                                    textAlign: "center",
                                    minWidth: "15%",
                                    flex: 1,
                                    marginRight: 4,
                                  },
                                },

                                {
                                  component: "text",
                                  text: service?.size?.name,
                                  style: {
                                    color: "rgba(0,0,0,1)",
                                    fontSize: 12,
                                    fontWeight: 400,
                                    fontFamily: "Poppins",
                                    minWidth: "10%",
                                    // maxWidth: "10%",
                                    flex: 1,
                                    marginRight: 4,
                                  },
                                },

                                {
                                  component: "text",
                                  text: service.value,
                                  style: {
                                    color: "rgba(0,0,0,1)",
                                    fontSize: 12,
                                    fontWeight: 400,
                                    fontFamily: "Poppins",
                                    minWidth: "8%",
                                    flex: 1,
                                    marginRight: 4,
                                    textAlign: "center",
                                  },
                                },
                              ],
                            },

                            // action
                            {
                              component: "container",
                              style: {
                                display: pageView === 1 ? "none" : "flex",
                                flex: 0.4,
                                flexDirection: "row",
                                alignItems: "flex-end",
                                justifyContent: "flex-end",
                                minWidth: "8%",
                                marginRight: 4,
                              },
                              children: [
                                {
                                  render: (
                                    <img
                                      src={EditIcon}
                                      style={{
                                        width: 40,
                                        height: 40,
                                        marginRight: 8,
                                        padding: 8,
                                        cursor: "pointer",
                                      }}
                                      onClick={async () => {
                                        let categoryList =
                                          await api.provider.getServicesOptionsSubcategory(
                                            service.category._id
                                          );
                                        let listSubCategory = [];
                                        categoryList.data.data.map((scat) => {
                                          listSubCategory.push({
                                            key: scat._id,
                                            value: scat,
                                            label: scat.name,
                                          });
                                        });

                                        let animalSizeList =
                                          await api.provider.getPetSize(
                                            service.animal_category._id
                                          );
                                        let listAnimalSize = [];
                                        animalSizeList.data.data.map((size) => {
                                          listAnimalSize.push({
                                            value: size,
                                            key: size._id,
                                            label: size.name,
                                            icon: size.image,
                                          });
                                        });
                                        await setAnimalSize(listAnimalSize);
                                        await setSubCategory(listSubCategory);

                                        setServiceID(service._id);
                                        setSelectedCategory(service.category);
                                        setSelectedSubCategory(
                                          service.sub_category
                                        );
                                        setSelectedAnimalSize(service.size);
                                        setServiceValue(
                                          service.value?.toFixed(2)
                                        );
                                        setServiceDescription(
                                          service.description
                                        );
                                        setAnimalType(service.animal_category);
                                        setPageView(0);
                                        SelectCategory.setValue(
                                          service.category?._id
                                        );
                                        await SelectSubCategory.setValue(
                                          service.sub_category?._id
                                        );
                                        await SelectAnimalSize.setValue(
                                          service.size?._id
                                        );
                                      }}
                                    />
                                  ),
                                },
                                {
                                  render: (
                                    <img
                                      src={DisableIcon}
                                      style={{
                                        width: 40,
                                        height: 40,
                                        marginRight: 8,
                                        padding: 8,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        SelectCategory.setValue(
                                          service.category._id
                                        );
                                      }}
                                    />
                                  ),
                                },
                                {
                                  render: (
                                    <img
                                      src={DeleteIcon}
                                      style={{
                                        width: 40,
                                        height: 40,
                                        marginRight: 8,
                                        padding: 8,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => console.log("delete")}
                                    />
                                  ),
                                },
                              ],
                            },
                            // Edit
                            // disable
                            // delete
                          ],
                        },
                        {
                          component: "text",
                          text: "Error",
                          style: {
                            margin: 4,
                            padding: 4,
                            color: "red",
                            fontSize: 16,
                            backgroundColor: "rgba(255,255,255,0.25)",
                            display:
                              importServices[i] &&
                              importServices[i].status === -1
                                ? "flex"
                                : "none",
                          },
                        },
                      ],
                    };
                  }),
                },
              ],
            },

            // ADD
            {
              component: "container",
              style: {
                display: pageView < 0 ? "flex" : "none",
                marginLeft: -50,
                marginTop: -50,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: 50,
                height: 50,
                maxHeight: 50,
                maxWidth: 50,
                borderRadius: 50,
                backgroundColor: theme.colors.pink2,
              },
              children: [
                {
                  render: (
                    <img
                      src={AddIcon}
                      style={{
                        width: 25,
                        height: 25,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        // categoria ?
                        // Tipo ?
                        // Go edit with not _id
                      }}
                    />
                  ),
                },
              ],
            },
          ],
        })}
      </div>
    </div>
  );
};
export default FormRegisterServicee;
