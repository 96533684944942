import React, { useState, useEffect } from "react";
import theme from "../../theme";
import api from "../../service";
import table from "../../components/table";

let Page = ({ show }) => {
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    (async () => {
      // const res = await api.manager.getHomeAdmin();
      // setPageData(res.data.data);
    })();
  }, []);

  if (show) {
    return {
      component: "container",
      style: {
        width: "100vw",
        height: "100vh",
        // backgroundColor: "#f00",
        alignItems: "center",
        justifyContent: "center",
        padding: 10,
        overflow: "hidden",
      },
      children: [
        table({
          url: "table",
          title: "Colaboradores",
          search: ["name", "code", 'phone'],
        }),
      ],
    };
  } else {
    return {
      component: "container",
    };
  }
};

export default Page;
